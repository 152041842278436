import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Image from "next/image";
import Link from "next/link";
import { default as PetImage } from "../../../assets/pet.svg";
import ShopImage from "../../../assets/store.svg";
import ScissorImage from "../../../assets/cut.svg";
import {
  Button,
  theme,
  getItemFromLocalStorage,
  FAV_ITEMS_KEY,
} from "@project/shared";
import { useRouter } from "next/router";
import { PetContext } from "../../molecules";

const Container = styled.div`
  box-shadow: -1.83697e-16px 3px 7px rgba(220, 220, 220, 0.34902);
  @media (max-width: 768px) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.01);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.09);
  }
`;
const Wrapper = styled.div`
  padding-top: 18px;
  max-width: 1480px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${theme.base};
  & p {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  & a {
    text-decoration: none;
  }
  & > div:first-child {
    margin-bottom: 16px;
  }
  @media (max-width: 1480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 768px) {
    & > div:first-child {
      margin-bottom: 0px;
    }
  }
  @media (max-width: 520px) {
    padding: 15px 10px;
  }
`;
const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;
const MainNav = styled(FlexBox)`
  & .super {
    color: ${theme.yellow1};
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
  }
  & .column-flex {
    margin-left: 29px;
    & p:first-child {
      margin-bottom: 5px;
    }
  }
  & button {
    white-space: break-spaces;
  }
  & > .navs {
    & a {
      color: ${theme.primaryText};
      font-size: 13px;
      line-height: 19px;
      font-weight: 400;
      padding: 0 15px;
    }
    & .headerLink--active {
      color: #1890ff;
    }
  }
  @media (max-width: 1215px) {
    & .column-flex {
      margin-left: 11px;
    }
    & button {
      margin-left: 10px;
      min-width: 120px;
    }
    & .image > div {
      max-width: 195px !important;
    }
  }
  @media (max-width: 1060px) {
    & .image > div {
      max-width: 300px !important;
    }
    & .navs {
      display: none;
    }
    & button {
      margin-left: auto;
    }
  }
  @media (max-width: 768px) {
    & .navs,
    button {
      display: none;
    }
    & .column-flex > p {
      white-space: nowrap;
    }
  }
  @media (max-width: 475px) {
    & .column-flex {
      margin-left: 0px;
      margin-top: 8px;
    }
    & > div {
      flex-direction: column;
    }
  }
`;
const ActionsNav = styled(FlexBox)`
  & > .navs {
    min-width: 406px;
    & a {
      color: ${theme.primary};
      font-size: 14px;
      line-height: 21px;
    }
    & .pet-dictionary--active {
      padding-bottom: 3px;
      border-bottom: 2px solid ${theme.primary};
    }
    @media (max-width: 1235px) and (min-width: 1216px) {
      min-width: 460px;
    }

    @media (max-width: 1215px) and (min-width: 1061px) {
      min-width: 418px;
      & a {
        font-size: 13px;
      }
    }
    @media (max-width: 1060px) {
      min-width: 360px;
    }
  }
  & > .navbar {
    & a {
      white-space: nowrap;
      color: ${theme.primary};
      font-size: 14px;
      line-height: 21px;
      padding-left: 10px;
      padding-right: 10px;
    }
    & .pet-dictionary--active {
      padding-bottom: 3px;
      border-bottom: 2px solid ${theme.primary};
    }
    @media (max-width: 1215px) {
      & a {
        font-size: 13px;
      }
    }
    @media (max-width: 1060px) {
      margin-bottom: 20px;
      & a {
        font-size: 13px;
      }
    }
  }
  @media (max-width: 1130px) {
    & > div:first-child {
      margin-right: 10px;
    }
  }
  @media (max-width: 1060px) {
    flex-wrap: wrap-reverse;
    & .navs {
      margin-left: 0px;
      margin-bottom: 10px;
    }
    & > div:first-child {
      width: 100%;
      > a {
        min-width: 33.33%;
      }
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const MailButton = styled.div`
  padding: 0 15px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Text = styled.p`
  color: ${theme.primary};
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  & span {
    border-radius: 9px;
    margin-right: 5px;
    background-color: ${theme.primary};
    color: ${theme.yellow};
    font-size: 11px;
    font-weight: 700;
    padding: 2px 10px;
    line-height: 16px;
    display: inline-flex;
    justify-content: center;
  }
`;
const Navs = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & a:hover {
    color: ${theme.link} !important;
  }
`;
const TabItem = styled.a<{ minwidth: number; active?: boolean }>`
  white-space: nowrap;
  border: 1px solid ${theme.primary};
  border-bottom: none;
  padding: 0 15px;
  min-height: 65px;
  min-width: ${({ minwidth }) => `${minwidth}px`};
  font-size: 15px;
  margin-right: 5px;
  text-align: center;
  background-color: ${({ active }) => (active ? theme.primary : theme.base)};
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ active }) => (active ? theme.base : theme.primary)};
  border-radius: 5px 5px 0px 0px;
  & svg {
    margin-right: 9px;
  }
  & path {
    fill: ${({ active }) => active && theme.base};
  }
  & .with-stroke {
    & path {
      fill: ${({ active }) => active && "none"};
      stroke: ${({ active }) => active && theme.base};
    }
    & path:nth-child(2) {
      fill: ${({ active }) => active && theme.base};
    }
  }
  &:hover {
    opacity: 0.9;
    color: ${({ active }) => (active ? theme.base : theme.link)} !important;
    & path {
      fill: ${({ active }) => !active && theme.link};
    }
    & .with-stroke {
      & path {
        fill: ${({ active }) => !active && "none"};
        stroke: ${({ active }) => !active && theme.link};
      }
      & path:nth-child(2) {
        fill: ${({ active }) => !active && theme.link};
      }
    }
  }
  @media (max-width: 1455px) {
    min-width: ${({ minwidth }) => `${minwidth - 10}px`};
  }
  @media (max-width: 1425px) {
    min-width: ${({ minwidth }) => `${minwidth - 25}px`};
    font-size: 14px;
  }
  @media (max-width: 1425px) {
    min-width: auto;
    padding: 0px 10px;
    font-size: 13px;
  }
`;
const Header = () => {
  const { favItem, setFavItemListCount } = useContext(PetContext);
  const router = useRouter();

  useEffect(() => {
    const favCount = getItemFromLocalStorage(FAV_ITEMS_KEY)?.length;
    setFavItemListCount(favCount);
  }, [getItemFromLocalStorage(FAV_ITEMS_KEY)]);

  const goTofavHandler = () => {
    router.push("/favourite");
  };
  return (
    <Container>
      <Wrapper>
        <MainNav>
          <FlexBox>
            <Link href="/">
              <a className="image">
                <Image
                  src="/logo.svg"
                  height={51}
                  width={260}
                  unoptimized={true}
                />
              </a>
            </Link>
            <div className="column-flex">
              <Text>ペットとの楽しい生活を応援いたします</Text>
              <Text>
                <span>全国122店舗</span>
                ペットショップ「コーワペッツ・ペッツフレンズ」
              </Text>
            </div>
          </FlexBox>
          <Navs className="navs">
            <Link href="/company">
              <a
                className={`${
                  router.pathname.startsWith("/company") && "headerLink--active"
                }`}
              >
                企業情報
              </a>
            </Link>
            <Link href="/news">
              <a
                className={`${
                  router.pathname.startsWith("/news") && "headerLink--active"
                }`}
              >
                お知らせ
              </a>
            </Link>
            <Link href="/about-us">
              <a>求人情報</a>
            </Link>
            <Link href="/contact">
              <a
                className={`${
                  router.pathname.startsWith("/contact") && "headerLink--active"
                }`}
              >
                お問い合わせ
              </a>
            </Link>
          </Navs>
          <MailButton>
            <a href="https://form.run/@kowapets " target="_blank">
              <img
                src="https://storage.googleapis.com/kowapets-production-bucket/0097c932-453f-461b-aac5-f0c138cefd60.png"
                height="40"
              />
            </a>
          </MailButton>
          <Button
            minwidth={146}
            minheight={40}
            type="primary"
            onClick={goTofavHandler}
          >
            お気に入り{"  "}
            <span className="super">{favItem || "0"}</span>
            {"  "}件
          </Button>
        </MainNav>
        <ActionsNav>
          <FlexBox>
            <Link href="/find-a-pet" passHref>
              <TabItem
                active={
                  router.pathname === "/find-a-pet" ||
                  router.pathname === "/pets/[id]"
                }
                minwidth={0}
              >
                <PetImage className="with-stroke" />
                ペットを探す
              </TabItem>
            </Link>
            <Link href="/search-store" passHref>
              <TabItem
                active={
                  router.pathname === "/search-store" ||
                  router.pathname === "/find-store"
                }
                minwidth={0}
              >
                <ShopImage />
                ペットショップを探す
              </TabItem>
            </Link>
            <Link href="/search-trimming" passHref>
              <TabItem
                active={router.pathname === "/search-trimming"}
                minwidth={0}
              >
                <ScissorImage />
                トリミングサロンを探す
              </TabItem>
            </Link>
          </FlexBox>
          <Navs className="navbar">
            <Link href="/about-us">
              <a
                className={`${
                  router.pathname == "/about-us" && "pet-dictionary--active"
                }`}
              >
                コーワペッツ・ペッツフレンズについて
              </a>
            </Link>
            <Link href="/voices">
              <a
                className={`${
                  router.pathname.startsWith("/voices") &&
                  "pet-dictionary--active"
                }`}
              >
                お客様の声
              </a>
            </Link>
            <Link href="/column-list">
              <a
                className={`${
                  router.pathname.startsWith("/column-list") &&
                  "pet-dictionary--active"
                }`}
              >
                コラム
              </a>
            </Link>
            <Link href="/pet-dictionary">
              <a
                className={`${
                  router.pathname.startsWith("/pet-dictionary")
                    ? "pet-dictionary--active"
                    : ""
                }`}
              >
                ペット図鑑
              </a>
            </Link>
          </Navs>
        </ActionsNav>
      </Wrapper>
    </Container>
  );
};

export { Header };
