import Image from "next/image";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  Button,
  Select,
  CheckboxGroup,
  TextField,
  theme,
  species,
  furColor,
  priceOption,
  PrefectureList,
  group,
  API,
  SortedPrefectureList,
} from "@project/shared";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { SearchQueryContext } from "../../..";
const prefectureOptions = PrefectureList.map((regions) => {
  const values = regions.values.filter((value: any) => value.display);
  if (values.length) {
    return {
      optname: regions.optname,
      values: values,
    };
  }
}).filter((v) => v);
prefectureOptions.unshift({
  optname: "すべて",
  values: [
    {
      name: "全ての都道府県",
      value: "",
      display: false,
    },
  ],
});
const Container = styled.section`
  background: #f4f6fa;
  padding: 32px 20px 45px 20px;
  border-radius: 10px;
  @media (max-width: 520px) {
    padding: 20px 10px;
  }
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 23px;
    height: 23px;
  }
  & p {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0px;
    padding-left: 11px;
    color: ${theme.primary};
  }
`;
const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 8px;
  & > div {
    & button {
      height: 40px;
      font-weight: 600;
    }
  }
  @media (max-width: 768px) {
    margin-top: 43px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 675px) {
    margin-top: 25px;
    grid-gap: 0 8px;
  }
  @media (max-width: 620px) {
    margin-top: 25px;
    grid-gap: 0 8px;
  }
  @media (max-width: 550px) {
    grid-gap: 0 8px;
  }
  @media (max-width: 450px) {
    grid-gap: 0 2px;
    & > div {
      & button {
        padding: 4px 8px;
        height: 40px;
        font-size: 12px;
      }
    }
  }
`;
const SelectWrapper = styled.div`
  display: grid;
  grid-gap: 10px 0;
  margin-top: 20px;
  & .ant-select-selection-placeholder {
    color: #313131;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    & font {
      vertical-align: center !important;
      & font {
        vertical-align: center !important;
      }
    }
  }
  @media (max-width: 768px) {
    margin-top: 30px;
    grid-row-gap: 30px 0;
    & .ant-select {
      & .ant-select-arrow {
        margin-right: 20px;
        & svg {
          width: 13px;
          height: 13px;
        }
      }
    }
  }
  @media (max-width: 520px) {
    grid-row-gap: 30px 0;
    & .ant-select {
      & .ant-select-arrow {
        margin-right: 8px;
        & svg {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
`;
const ConditionsWrapper = styled.div`
  margin-top: 34px;
  & .checkbox-label {
    color: ${theme.primary};
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  & .ant-checkbox-group {
    display: grid;
    grid-gap: 10px 0px !important;
  }
  & .ant-checkbox-wrapper {
    & .ant-checkbox {
      margin-right: 4px;
      & .ant-checkbox-inner {
        width: 28px !important;
        height: 28px !important;
        border: 1px solid #d2d2d2 !important;
        border-radius: 5px;
      }
    }
  }
  @media (max-width: 520px) {
    margin-top: 30px;
    & .checkbox-label {
      font-size: 18px;
      margin-bottom: 16px;
    }
    & .ant-checkbox-group {
      grid-gap: 15px 0px !important;
    }
    & .ant-checkbox-wrapper {
      & .ant-checkbox {
        margin-right: 5px;
        & .ant-checkbox-inner {
          width: 25px !important;
          height: 25px !important;
          border-radius: 5px;
        }
      }
      & > span:nth-child(2) {
        font-size: 16px;
      }
    }
  }
`;
const FreeWordStyled = styled.div`
  margin-top: 15px;
`;

const SubmitBtnStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  & Button {
    width: 100%;
    background: ${theme.primary};
    border-radius: 23px;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    & :hover {
      background: ${theme.primary};
      color: #fff;
    }
    & img {
      margin-right: 14px;
    }
  }
  @media (max-width: 520px) {
    margin-top: 50px;
    & Button {
      height: 40px;
      border-radius: 25px;
      font-size: 16px;
    }
    & img {
      height: 18px;
    }
  }
`;

const ConditionsBtnStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0;
  & .ant-btn:focus {
    background: none;
  }
  & Button {
    & > span {
      text-decoration: underline;
    }
    background: none;
    font-size: 14px;
    font-weight: 400;
    color: #1a1a1a;
    & :hover {
      background: none;
      color: #1a1a1a;
    }
  }
  @media (max-width: 520px) {
    margin-top: 8px;
    & Button {
      font-size: 18px;
    }
  }
`;

export interface ISearchBar {
  active: string;
  initial: {
    group: string;
    species: string;
    fur_color: string;
    gender: string;
    price: string;
    prefecture: string;
    shop_name: string;
    vaccination: string;
    keyword: string;
    sortby?: string;
    commitment_conditions: any[];
  };
  changeActive: (arg: string) => void;
}

const furColors = [{ name: "全ての毛色（系統）", value: "" }, ...furColor];
const priceOptions = [
  { name: "全ての価格", value: "" },
  ...priceOption,
  { name: "価格未登録", value: 1 },
];
const genders = [
  {
    name: "全ての性別",
    value: "",
  },
  {
    name: "男の仔",
    value: "boy",
  },
  {
    name: "女の仔",
    value: "girl",
  },
];
const vaccinationOptions = [
  { name: "全ての接種状況", value: "" },
  { name: "１回", value: "1" },
  { name: "２回", value: "2" },
  { name: "３回以上", value: "3" },
  { name: "未接種", value: "unvaccinated" },
];

const SearchBar: React.FC<ISearchBar> = ({ active, changeActive, initial }) => {
  const { UpdateQuery } = useContext(SearchQueryContext);
  const { t } = useTranslation();
  const [shop, setShop] = useState([]);
  const [prefecture, setprefecture] = useState("");
  const router = useRouter();
  useEffect(() => {
    fetchShopData();
  }, [prefecture]);
  useEffect(() => {
    //
  }, [shop]);

  useEffect(() => {
    setprefecture(initial?.prefecture);
  }, [initial?.prefecture]);

  const fetchShopData = async () => {
    const shopResponse = await API.get("/consumer/shops", {
      params: {
        prefectures: prefecture,
        pageSize: 1000,
      },
    });
    const shops = shopResponse?.data?.rows?.map((shop) => ({
      name: shop?.name,
      value: shop?.name,
      prefecture: shop?.prefecture,
    }));
    if (prefecture !== "") {
      shops.unshift({
        name: "全てのショップ",
        value: "",
      });
      setShop(shops);
    } else {
      const shopList: any = [
        {
          optname: "全てのショップ",
          values: [
            {
              name: "全てのショップ",
              value: "",
              display: false,
            },
          ],
        },
      ];
      SortedPrefectureList.forEach((area) => {
        area.values.forEach((prefecture) => {
          const prefectureShops = shops.filter(
            (shop) => shop.prefecture === prefecture.name
          );
          if (prefectureShops.length > 0) {
            shopList.push({
              optname: prefecture.name,
              values: prefectureShops,
            });
          }
        });
      });
      setShop(shopList);
    }
  };

  const query = {};
  const formik = useFormik({
    initialValues: initial,
    enableReinitialize: true,
    onSubmit: (values) => {
      Object.keys(values).map((key) => {
        if (values[key]) {
          query[key] = values[key];
        }
      });
      values["category"] = active;
      router.push({
        pathname: "/find-a-pet",
        query,
      });
    },
  });

  const commitmentConditions = [
    { label: t("Recently born"), value: "0" },
    { label: t("With pedigree"), value: "1" },
    { label: t("There is a video"), value: "2" },
  ];

  const speciesOptions = (): any => {
    switch (active) {
      case "cat":
        return [
          { category: "", name: "全ての品種", value: "" },
          ...species.cat,
        ];
      case "fish":
        return [
          { category: "", name: "全ての品種", value: "" },
          ...species.fish,
        ];
      case "small_pets":
        return [
          { category: "", name: "全ての品種", value: "" },
          ...species.small_pets,
        ];
      default:
        return [
          { category: "", name: "全ての品種", value: "" },
          ...species.dog,
        ];
    }
  };

  const resetForm = (category = "") => {
    category && changeActive(category);
    const query = {
      page: 1,
      group: "",
      species: "",
      fur_color: "",
      gender: "",
      price: "",
      prefecture: "",
      shop_name: "",
      vaccination: "",
      keyword: "",
      sortby: "1",
      category: category || active,
      commitment_conditions: [],
    };
    UpdateQuery({ ...query, category: "" }, "add");
    router.push({
      pathname: "/find-a-pet",
      query,
    });
  };

  const groupOptions = () => {
    switch (active) {
      case "fish":
        return [{ name: "全てのグループ", value: "" }, ...group.fish];
      case "small_pets":
        return [{ name: "全てのグループ", value: "" }, ...group.small_pets];
    }
  };

  return (
    <Container>
      <Title>
        <Image
          src="/search1.svg"
          height="20px"
          width="20px"
          unoptimized={true}
        />
        <p>{t("Refined search")}</p>
      </Title>
      <form onSubmit={formik.handleSubmit}>
        <ButtonsWrapper>
          <Button
            htmlType="button"
            borderradius={8}
            onClick={() => {
              if (active != "dog") {
                resetForm("dog");
              }
            }}
            className={`${
              active == "dog" ? "ant-btn-primary" : "ant-btn-ghost"
            }`}
            fontsize={14}
          >
            {t("Dog")}
          </Button>
          <Button
            htmlType="button"
            borderradius={8}
            onClick={() => {
              if (active != "cat") {
                resetForm("cat");
              }
            }}
            className={`${
              active == "cat" ? "ant-btn-primary" : "ant-btn-ghost"
            }`}
            fontsize={14}
          >
            {t("Cat")}
          </Button>
          <Button
            htmlType="button"
            borderradius={8}
            onClick={() => {
              if (active != "small_pets") {
                resetForm("small_pets");
              }
            }}
            className={`${
              active == "small_pets" ? "ant-btn-primary" : "ant-btn-ghost"
            }`}
            fontsize={14}
          >
            {t("Small animals")}
          </Button>
          <Button
            htmlType="button"
            borderradius={8}
            onClick={() => {
              if (active != "fish") {
                resetForm("fish");
              }
            }}
            className={`${
              active == "fish" ? "ant-btn-primary" : "ant-btn-ghost"
            }`}
            fontsize={14}
          >
            {t("Aquarium fish")}
          </Button>
        </ButtonsWrapper>
        <SelectWrapper>
          <Select
            native
            radius="8px"
            options={groupOptions()}
            height="46px"
            hide={["dog", "cat"].includes(active)}
            placeholder={`${t("Group")}`}
            name="group"
            value={formik.values.group || ""}
            onChange={(event) => formik.setFieldValue("group", event)}
          />
          <Select
            native
            radius="8px"
            options={
              (active === "small_pets" || active === "fish") &&
              formik.values.group !== ""
                ? speciesOptions().filter(
                    (speciesData) =>
                      speciesData.category === "" ||
                      speciesData.category === Number(formik.values.group)
                  )
                : speciesOptions()
            }
            height="46px"
            placeholder={`${t("Variety")}`}
            name="species"
            value={formik.values.species || ""}
            onChange={(event) => formik.setFieldValue("species", event)}
          />
          <Select
            native
            radius="8px"
            options={furColors}
            hide={["pet", "fish"].includes(active)}
            height="46px"
            placeholder={`${t("Fur color")}`}
            name="fur_color"
            value={formik.values.fur_color || ""}
            onChange={(event) => formik.setFieldValue("fur_color", event)}
          />
          <Select
            native
            radius="8px"
            options={genders}
            hide={["fish"].includes(active)}
            height="46px"
            onChange={(event) => formik.setFieldValue("gender", event)}
            placeholder={`${t("Sex")}`}
            name="gender"
            value={formik.values.gender || ""}
          />
          <Select
            native
            radius="8px"
            options={priceOptions}
            height="46px"
            onChange={(event) => formik.setFieldValue("price", event)}
            placeholder={`${t("Price")}`}
            name="price"
            value={formik.values.price || ""}
          />
          <Select
            native
            radius="8px"
            type="optgroup"
            options={prefectureOptions}
            height="46px"
            onChange={(event) => {
              // fetchShopData(event);
              setprefecture(event);
              formik.setFieldValue("prefecture", event);
              formik.setFieldValue("shop_name", "");
            }}
            placeholder={`${t("Prefecture")}`}
            name="prefecture"
            value={formik.values.prefecture || ""}
          />
          {prefecture === "" ? (
            <Select
              native
              radius="8px"
              options={shop}
              height="46px"
              type="optgroup"
              onChange={(event) => formik.setFieldValue("shop_name", event)}
              placeholder={`${t("Shop's name")}`}
              name="shop_name"
              value={formik.values.shop_name || ""}
            />
          ) : (
            <Select
              native
              radius="8px"
              options={shop}
              height="46px"
              onChange={(event) => formik.setFieldValue("shop_name", event)}
              placeholder={`${t("Shop's name")}`}
              name="shop_name"
              value={formik.values.shop_name || ""}
            />
          )}
          <Select
            native
            radius="8px"
            options={vaccinationOptions}
            hide={["pet", "fish"].includes(active)}
            height="46px"
            onChange={(event) => formik.setFieldValue("vaccination", event)}
            placeholder={`${t("Vaccination")}`}
            name="vaccination"
            value={formik.values.vaccination || ""}
          />
        </SelectWrapper>
        <ConditionsWrapper>
          <CheckboxGroup
            label={t("Commitment conditions")}
            options={
              ["pet", "fish"].includes(active)
                ? commitmentConditions.slice(2)
                : commitmentConditions
            }
            name="commitment_conditions"
            value={formik.values.commitment_conditions}
            onChange={(value) =>
              formik.setFieldValue("commitment_conditions", value)
            }
          />
        </ConditionsWrapper>
        <FreeWordStyled>
          <TextField
            name="keyword"
            value={formik.values.keyword}
            onChange={formik.handleChange}
            placeholder="キーワード"
          />
        </FreeWordStyled>
        <SubmitBtnStyled>
          <Button
            htmlType="submit"
            borderradius={23}
            className="primary"
            fontsize={14}
            minheight={46}
          >
            <Image
              src="/ico-search.svg"
              width={16}
              height={16}
              unoptimized={true}
            />
            {t("Search by this condition")}
          </Button>
        </SubmitBtnStyled>
        <ConditionsBtnStyled>
          <Button
            borderradius={23}
            fontsize={14}
            minheight={46}
            onClick={() => resetForm()}
          >
            {t("Clear the conditions")}
          </Button>
        </ConditionsBtnStyled>
      </form>
    </Container>
  );
};

export { SearchBar };
